import { userStore } from '@context/userStore';
import Container from '@elements/Container';
import Footer from '@layout/Footer';
import Navbar from '@layout/Navbar';
import { getFavoritesList } from '@providers/favorite';
import { getRecommendations } from '@providers/recommendations';
import Category from '@sections/Category';
import DocumentList from '@sections/DocumentList';
import Feature from '@sections/Feature';
import HeroSection from '@sections/HeroSection';
import VerifyEmail from '@sections/VerifyEmailSection';
import Video from '@sections/Video';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function Home({ recommendations }) {
  const { profileId, createdRegularProfile } = userStore();
  const isLoggedIn = userStore((state) => state.isLoggedIn);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayVerifyEmailMessage, setDisplayVerifyEmailMessage] = useState(createdRegularProfile);

  useEffect(() => {
    if (profileId) {
      const fetchFavorites = async () => {
        const response = await getFavoritesList(profileId);
        if (response && response.status === 200) {
          setDocuments(response.data);
        } else {
          setDocuments([]);
        }
        setLoading(false);
      };
      fetchFavorites();
    } else {
      setLoading(false);
    }
  }, [profileId]);

  const favorites = documents?.map((document) => ({
    documentId: document?.document_id,
  }));

  return (
    <div>
      <Head>
        <title>BouwZo - Jouw bron voor bouwkennis</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar />
      <div className="flex flex-col gap-8 text-clip">
        <HeroSection />
        {displayVerifyEmailMessage ? (
          <VerifyEmail setDisplayVerifyEmailMessage={setDisplayVerifyEmailMessage} />
        ) : null}
        <Category tile />
        {!isLoggedIn && (
          <Container variant="white">
            <Video />
          </Container>
        )}
        <Feature />
        {loading ? (
          <Container>
            <div className="grid gap-20 pb-16 md:grid-cols-2 lg:grid-cols-4">
              {[...Array(4)].map((_, index) => (
                <div className="flex flex-col gap-8" key={index}>
                  <Skeleton className="h-[300px] w-[240px]" />
                  <Skeleton className="h-[300px] w-[240px]" />
                </div>
              ))}
            </div>
          </Container>
        ) : (
          <>
            {recommendations.map((recommendationList, index) => (
              <DocumentList
                key={index}
                documentListData={recommendationList}
                documentFavorites={favorites}
              />
            ))}
          </>
        )}
      </div>
      <div className="pt-10">
        <Footer />
      </div>
    </div>
  );
}

export async function getServerSideProps(context) {
  const { req, res } = context;

  const response = await getRecommendations(req, res, 2);
  if (response && response?.status === 200) {
    const recommendations = response.data;
    return {
      props: {
        recommendations: recommendations,
      },
    };
  } else {
    return {
      props: {
        recommendations: [],
      },
    };
  }
}
