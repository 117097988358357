export default function Video() {
  return (
    <iframe
      className="w-full"
      height="720"
      src="https://www.youtube-nocookie.com/embed/ocDh_BF_t5Y"
      title="BouwZo animatie met uitleg"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
}
