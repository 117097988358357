import Container from '@elements/Container';
import Status from '@elements/Status';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const data = {
  image: 'https://unsplash.com/photos/x-ghf9LjrVg',
  imageAlt: '',
  markdown: `## BouwZo jouw bron voor betrouwbare kennis


  BouwZo is jouw betrouwbare kennisplatform. De bron van bouwkennis. Start vandaag nog door een profiel aan te maken en de licentie te kiezen die bij je past. Zo krijg je toegang tot alle kennis.

  Deel jouw kennis ook op [BouwZo](https://over.bouwzo.nl/voor-kennisleveranciers/). Neem contact met ons op en maak het aanbod voor professionals compleet.
  Bij BouwZo zijn we meer dan een platform; we zijn jouw partner in de bouwsector.

  BouwZo is hét startpunt voor:
  * Kennis
  * Innovatie en ontwikkeling
  * Betrouwbaarheid`,
};

const LargeScreenComponent = () => (
  <div className="relative hidden h-fit lg:flex">
    <img className="size-1/2 max-w-2xl flex-1" src="/hero.webp" alt={'/public/hero.webp'} />
    <div className="absolute h-full w-3/5 content-center px-10">
      <div className="relative left-[80%] flex max-w-2xl flex-col gap-6 bg-white px-10 pb-4 pt-10 shadow-xl">
        <MarkdownContent />
      </div>
    </div>
  </div>
);

const MediumScreenComponent = () => (
  <div className="container mx-auto hidden h-fit max-w-4xl flex-col md:flex lg:hidden">
    <img className="w-[70%] max-w-2xl object-bottom" src="/hero.webp" alt={'/public/hero.webp'} />
    <div className="my-auto ml-[300px] mt-[-300px] h-full w-3/5 pr-4">
      <div className="container flex flex-col gap-6 bg-white px-5 pb-4 pt-5 shadow-xl">
        <Status variant="nieuw" />
        <MarkdownContent />
      </div>
    </div>
  </div>
);

const SmallScreenComponent = () => (
  <div className="container relative mx-auto flex max-w-2xl flex-col justify-center md:hidden">
    <img className="w-fit object-bottom" src="/hero.webp" alt={'/public/hero.webp'} />
    <div className="h-full content-center px-2">
      <div className="container mt-[-30px] flex h-fit flex-col gap-6 bg-white p-4 shadow-xl">
        <Status variant="nieuw" />
        <MarkdownContent />
      </div>
    </div>
  </div>
);

const MarkdownContent = () => (
  <div className="prose pb-2 prose-h2:text-xl prose-h2:font-bold prose-h2:leading-6 prose-p:leading-5 prose-a:font-light prose-a:text-accent prose-ul:-mt-4 prose-li:-m-1 md:pb-4">
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{data.markdown}</ReactMarkdown>
  </div>
);

export default function Feature() {
  return (
    <Container variant="white">
      <div className="mt-12 h-full">
        <LargeScreenComponent />
        <MediumScreenComponent />
        <SmallScreenComponent />
      </div>
    </Container>
  );
}
