import { userStore } from '@context/userStore';
import Logo from '@elements/Logo';
import Button from '@elements/buttons/Button';
import LinkButton from '@elements/buttons/LinkButton';
import DynamicSubscriptionComponent from '@flows/subscription/DynamicSubscriptionComponent';
import { usePlausible } from 'next-plausible';
import { useRouter } from 'next/router';

const data = {
  title: 'Welkom op',
  titleTwo: 'BouwZo',
  description: `Jouw bron voor bouwkennis. Ontdek een wereld van kennis, waarbij betrouwbaarheid voorop staat. Speciaal voor professionals. Eenvoudig te navigeren en doorzoekbaar, zodat je snel vindt wat je nodig hebt. `,
  state: 'no-license',
  isLoggedIn: true,
  textboxTitle: 'Waar ben je naar op zoek?',
};

export default function HeroSection() {
  const isLoggedIn = userStore((state) => state.isLoggedIn);
  const router = useRouter();
  function handleClick() {
    router.push({ pathname: '/subscription', query: { state: 'choose-subscription' } });
  }

  const plausibleEvent = usePlausible();

  const defaultAltComponent = (
    <Button
      onClick={() => {
        if (isLoggedIn) {
          plausibleEvent('Bekijk Licenties Ingelogd: Klik');
        } else {
          plausibleEvent('Bekijk Licenties Uitgelogd: Klik');
        }
        handleClick();
      }}
      icon="arrow-right"
      buttonType="primary"
      label="Bekijk licenties"
      iconPosition="right"
    />
  );

  const standardAltComponent = (
    <LinkButton
      onClick={() => plausibleEvent('Bekijk Licenties Uitgelogd: Klik')}
      href="/subscription?state=choose-subscription"
      icon="arrow-right"
      buttonType="primary"
      label="Breid licentie uit"
      iconPosition="right"
    />
  );

  const plusAltComponent = null;
  const enterpriseAltComponent = null;

  return (
    <div className="relative bg-primary">
      {/* MD */}
      <div className="relative overflow-hidden">
        <div className="relative mx-auto hidden max-w-7xl md:block md:px-6 lg:px-4">
          <div className="container flex-col md:py-14 lg:w-1/2">
            <h1 className="font-primary text-primary-dark md:text-4xl lg:text-7xl">
              {data.title} <span className="block">{data.titleTwo}</span>
            </h1>
            <p className="h-1/2 py-4 pr-[75px] font-secondary text-primary-dark md:w-3/4 md:pb-12 lg:w-full">
              {data.description}
            </p>
            <div className="pt-4">
              <DynamicSubscriptionComponent
                defaultAltComponent={defaultAltComponent}
                standardAltComponent={standardAltComponent}
                plusAltComponent={plusAltComponent}
                enterpriseAltComponent={enterpriseAltComponent}
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-[-360px] right-[-140px] hidden size-[700px] opacity-20 md:block lg:-right-12 lg:bottom-6 lg:h-[400px] lg:w-[650px]">
          <Logo.issoBigO.svg className="text-functional-contrast/20" />
        </div>
      </div>
      {/* MD- */}
      <div className="relative md:hidden">
        <div className="relative overflow-hidden">
          <div className="absolute right-[-340px] size-[600px]" style={{ contain: 'paint' }}>
            <Logo.issoBigO.svg className="text-functional-contrast opacity-20" />
          </div>
          <div className="mx-4">
            <div className="relative overflow-hidden">
              <div className="container flex-col gap-4 pb-10 pt-8 md:w-2/3">
                <h1 className="text-4xl font-light tracking-wide text-primary-dark">
                  {data.title}
                  <span className="block">{data.titleTwo}</span>
                </h1>
                <p className="h-1/2 py-4 font-light text-primary-dark">{data.description}</p>
                <div className="pb-8 pt-4">
                  <DynamicSubscriptionComponent
                    defaultAltComponent={defaultAltComponent}
                    standardAltComponent={standardAltComponent}
                    plusAltComponent={plusAltComponent}
                    enterpriseAltComponent={enterpriseAltComponent}
                  />
                </div>
                <div className="pb-8 pt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
